import React, { Component } from 'react';
import './About.css';

class AboutButton extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false
    }
  }

  render () {
    const { className = '', ...rest } = this.props
    return (
      <div>
        <button {...rest}
                className={`button ${className}`}
                onClick={() => this.setState({ isOpen: true })}>
          <span className="icon">
            <i className="fas fa-book"></i>
          </span>
          <span className="is-uppercase">About</span>
        </button>
        <AboutModal
          isOpen={this.state.isOpen}
          onClose={() => this.setState({ isOpen: false })}/>
      </div>
    )
  }

}

class AboutModal extends Component {
  render () {
    return (
      <div className={'modal' + (this.props.isOpen ? ' is-active' : '')}>
        <div className="modal-background"
             onClick={this.props.onClose}>
        </div>
        <div className="modal-content">
          <div className="box">
            <h4 className="title is-4">
              <span
                className="icon is-medium">
                <i className="fas fa-exclamation"></i>
              </span>
              Changelog
            </h4>
            <h6>2022-10-07</h6>
            <p>- Fixed: Added missing engine.getState() method.</p>
            <p>- Fixed: Added missing engine.cashOut() method (only to prevent errors in scripts which use it).</p>
            <p>- Fixed: Bets placed after calling stop() are now ignored.</p>
            <h6>2022-03-12</h6>
            <p>- Added: Additional stats to track game win/skip ratios.</p>
            <h6>2020-12-06</h6>
            <p>- Added: Missing SHA256() and gameResultFromHash() API functions.</p>
            <p>- Fixed: Engine history holds only the last 50 games.</p>
            <p>- Fixed: Pre-loaded engine history with working gameId.</p>
            <h6>2020-11-29</h6>
            <p>- Fixed: Minor code cleanup and optimization.</p>
            <p>- Added: Pre-loaded game history to engine.</p>
            <h6>2020-07-19</h6>
            <p>- Improved performance of the chart.</p>
            <p>- Adjusted statistic label grammar to be more specific.</p>
            <h6>2020-01-28</h6>
            <p>- Added: Option to disable log (improves performance in some cases).</p>
            <p>- Added: Results show the full cost of the longest lose streak.</p>
            <p>- Fixed: Wager must to be divisible by 100 to valid and placed.</p>
            <p>- Fixed: Lowest bet now correctly says 0 when none were placed.</p>
            <p>- Fixed: Bug with some scripts requiring game history at start.</p>
            <p>- Fixed: Script fails to start with improper config formatting.</p>
            <h6>2019-10-29</h6>
            <p>- Improved graph for large number of games.</p>
            <p>- Added: Input type for numbers with script config.</p>
            <p>- Added: Additional statistics/info to results.</p>
            <p>- Fixed: Blank screen on script selection.</p>
          </div>
        </div>
        <button
          className="modal-close is-large"
          onClick={this.props.onClose}>
        </button>
      </div>
    )
  }
}

export { AboutModal , AboutButton }
